import * as rules from './rules';
import { isEmptyString } from '../util/string';
import { breakpoints } from '../../components/primitives/constants';
import { AccountLogin } from '../../types/RootState';
import { FeatureName } from './featureNames';

export const shouldShowFeature = (login: AccountLogin, featureName: FeatureName) => {
  if (isEmptyString(featureName)) {
    throw new Error('featureName was not provided');
  }

  switch (featureName) {
    case FeatureName.CALL_CENTER:
      return rules.showCallCenterTab(login);
    case FeatureName.CAPACITY:
      return rules.showCapacityTab(login);
    case FeatureName.CHANGE_LOCATION_DROPDOWN:
      return rules.showChangeLocationDropdown(login);
    case FeatureName.CHAT:
      return rules.showChat(login);
    case FeatureName.DISABLE_RESERVATIONS:
      return rules.showDisableReservations(login);
    case FeatureName.EMERGENT:
      return rules.showEmergent(login);
    case FeatureName.FACESHEET_MODAL_PAPERWORK_RESPONSES_TAB:
      return rules.showPaperworkResponsesTab(login);
    case FeatureName.FACESHEET_MODAL_BOOKING_DETAILS_TAB:
      return rules.showFacesheetModalBookingDetailsTab(login);
    case FeatureName.FACESHEET_MODAL_BOOKING_HISTORY_TAB:
      return rules.showFacesheetModalBookingHistoryTab(login);
    case FeatureName.PAPERWORK_RACE_AND_ETHNICITY_FIELDS:
      return rules.showRaceAndEthnicityFields(login);
    case FeatureName.FACESHEET_MODAL_CONSENT_FORMS_TAB:
      return rules.showFacesheetModalConsentFormsTab(login);
    case FeatureName.FACESHEET_MODAL_LAB_RESULTS_TAB:
      return rules.showFacesheetModalLabResultsTab(login);
    case FeatureName.FACESHEET_MODAL_VISIT_SUMMARY_TAB:
      return rules.showFacesheetModalVisitSummaryTab(login);
    case FeatureName.HANDOFF_PAPERWORK:
      return rules.showPaperlessHandoff(login);
    case FeatureName.HANDOFF_VIDEO_VISIT:
      return rules.showVideoVisitHandoff(login);
    case FeatureName.NEXTCARE_MISSING_LOCATION_TEXT:
      return rules.showNextCareMissingLocationText(login);
    case FeatureName.HELLO_REPORTS_BUTTON:
      return rules.showHelloReportsButton(login);
    case FeatureName.HELLO_SETTINGS_BUTTON:
      return rules.showSettingsTabLocations(login) || rules.showSettingsTabGroups(login);
    case FeatureName.SETTINGS_TAB:
      return (
        rules.showSettingsTabUsers(login) ||
        rules.showSettingsTabLocations(login) ||
        rules.showSettingsTabGroups(login) ||
        rules.showSettingsTabCustomQuestions(login)
      );
    case FeatureName.HELLO_USERS_BUTTON:
      return rules.showSettingsTabUsers(login);
    case FeatureName.SETTINGS_TAB_USERS_TAB_SERVER_SIDE:
      return rules.showSettingsTabUsersServerSide(login);
    case FeatureName.SETTINGS_TAB_CUSTOM_QUESTIONS_TAB:
      return rules.showSettingsTabCustomQuestions(login);
    case FeatureName.SETTINGS_TAB_SFTP_INTEGRATIONS_TAB:
      return rules.showSettingsTabSftpIntegrations(login);
    case FeatureName.SETTINGS_TAB_LOCATIONS_TAB:
      return rules.showSettingsTabLocations(login);
    case FeatureName.SETTINGS_TAB_GROUPS_TAB:
      return rules.showSettingsTabGroups(login);
    case FeatureName.SETTINGS_TAB_PROVIDERS_TAB:
      return rules.showSettingsTabProviders(login);
    case FeatureName.SETTINGS_TAB_PRACTICES_TAB:
      return rules.showSettingsTabPractices(login);
    case FeatureName.REPORTS_ADDRESS_VALIDATION_REPORT_TAB:
      return rules.showAddressValidationReportsTab(login);
    case FeatureName.REPORTS_BRANDED_SCHEDULER_REPORT_TAB:
      return rules.showBrandedSchedulerReportsTab(login);
    case FeatureName.REPORTS_REVIEWS_TAB:
      return rules.showReviewsReportsTab(login);
    case FeatureName.REPORTS_PATIENT_BOOST_TAB:
      return rules.showPatientBoostReportsTab(login);
    case FeatureName.REPORTS_PAPERLESS_TAB:
      return rules.showPaperlessReportsTab(login);
    case FeatureName.REPORTS_TELEMED_TAB:
      return rules.showTelemedReportsTab(login);
    case FeatureName.REPORTS_LIVE_DASHBOARD_TAB:
      return rules.showLiveDashboardReportsTab(login);
    case FeatureName.REPORTS_LIVE_HOURLY_DASHBOARD_TAB:
      return rules.showLiveHourlyDashboardReportsTab(login);
    case FeatureName.REPORTS_HISTORICAL_DASHBOARD_TAB:
      return rules.showHistoricDashboardReportsTab(login);
    case FeatureName.RESERVATION_HOURS_TAB:
      return rules.showReservationHoursTab(login);
    case FeatureName.REVIEWS_MODERATION:
      return rules.showReviewsModerationTab(login);
    case FeatureName.PATIENTS_ACCOUNTS_TAB:
      return rules.showPatientsAccountsTab(login);
    case FeatureName.QUEUE_TAB:
      return rules.showQueueTab(login);
    case FeatureName.REPORTS_TAB:
      return rules.showReportsTab(login);
    case FeatureName.TELEMED_FLAG:
      return rules.showTelemedFlag(login);
    case FeatureName.TRIAGED:
      return rules.showTriaged(login);
    case FeatureName.PATIENTS_LAB_RESULTS:
      return rules.showPatientsLabResultsTab(login);
    case FeatureName.PATIENTS_TAB:
      return rules.showPatientsTab(login);
    case FeatureName.HELP_CENTER:
      return rules.showHelpCenter(login);
    case FeatureName.PAYMENTS_TAB:
    case FeatureName.HELLO_PAYMENTS_BUTTON:
      return rules.showPayments(login);
    case FeatureName.FACESHEET_MODAL_PAYMENTS_TAB:
      return rules.showPaymentsPOS(login);
    case FeatureName.PAYMENTS_SETTINGS_TAB:
      return rules.showPaymentsSettings(login);
    case FeatureName.PAYMENTS_SMS_NON_SOLV_PATIENTS:
      return rules.showPaymentsNonSolv(login);
    case FeatureName.PLATFORM_USAGE_ALERTS:
      return rules.showPlatformUsageAlerts(login);
    case FeatureName.LOCATION_SETTINGS_INTEGRATIONS:
      return rules.showLocationSettingsIntegrations(login);
    case FeatureName.LOCATION_SETTINGS_GENERAL_INFORMATION:
      return rules.showLocationSettingsGeneralInformation(login);
    case FeatureName.LOCATION_SETTINGS_HOURS_AND_SCHEDULING:
      return rules.showLocationSettingsHoursAndScheduling(login);
    case FeatureName.LOCATION_SETTINGS_INSURANCE:
      return rules.showLocationSettingsInsurance(login);
    case FeatureName.LOCATION_SETTINGS_INTERNAL_ADMIN:
      return rules.showLocationSettingsConsentFormUpload(login);
    case FeatureName.LOCATION_SETTINGS_LAB_RESULTS:
      return rules.showLocationSettingsLabResults(login);
    case FeatureName.LOCATION_SETTINGS_CONSENT:
      return rules.showLocationSettingsInternalAdmin(login);
    case FeatureName.LOCATION_SETTINGS_HOURS_RESERVATION_CAPACITY:
      return rules.showLocationSettingsHoursReservationCapacity(login);
    case FeatureName.LOCATION_SETTINGS_PAPERWORK:
      return rules.showLocationSettingsPaperwork(login);
    case FeatureName.LOCATION_SETTINGS_SERVICES:
      return rules.showLocationSettingsServices(login);
    case FeatureName.LOCATION_SETTINGS_SINGLE_USE_LINKS:
      return rules.showLocationSettingsSingleUseLinks(login);
    case FeatureName.LOCATION_SETTINGS_PATIENT_COMMUNICATIONS:
      return rules.showLocationSettingsPatientCommunications(login);
    case FeatureName.CLINIC_ACCOUNT_GROUPS_EDITOR:
      return rules.showClinicAccountsGroupsEditor(login);
    case FeatureName.CUSTOM_QUICK_REPLIES:
      return rules.showCustomQuickReplies(login);
    case FeatureName.CONNECT_TAB:
      return rules.showConnectTab(login);
    case FeatureName.MESSAGING_TAB:
      return rules.showMessagingTab(login);
    case FeatureName.PAYMENT_REFUNDS:
      return rules.showPaymentRefunds(login);
    case FeatureName.SETTINGS_TAB_SCHEDULING:
      return rules.showVtsSchedulingSettings(login);
    case FeatureName.CAPACITY_INSIGHTS:
      return rules.showCapacityInsightsTab(login);
    case FeatureName.OPERATIONS_BENCHMARK:
      return rules.showOperationsBenchmarkTab(login);
    case FeatureName.ADMIN_TOOLS:
      return rules.showAdminTools(login);
    default:
      throw new Error(`shouldShowFeature doesn't recognize this featureName: ${featureName}`);
  }
};

export const isSameOrBelowXsBreakpoint = (width: number) => width <= breakpoints.xs;
export const isSameOrBelowSmBreakpoint = (width: number) => width <= breakpoints.sm;
export const isSameOrBelowMdBreakpoint = (width: number) => width <= breakpoints.md;
export const isSameOrBelowTabletBreakpoint = (width: number) => width <= breakpoints.tablet;
